<template>
    <div id="return-proposal">

        <h1 class="visually-hidden">Nouvelle demande de retour - PharmaConnect</h1>

        <div id="printMeRetour" v-show="false" :key="retourSaved.lines.length">
            <div class="invoice-box" style="background-color: white; width:100%">
                <table style="width: 100%">
                    <tr class="top" style="margin-bottom: 10px">
                        <td colspan="2" style="border-bottom: 1px solid black;">
                            <table>
                                <tr>
                                    <td class="title" style="text-align: left;width:10%;margin: 0;padding:0">
                                        <img :src="src_logo" style="width:200px;height: 100px">
                                    </td>
                                    <td style="text-align: left;width:30%;margin: 0;padding:0">
                                        <h6 style="margin: 0;text-align: center;font-size:medium;font-weight: bold;">7
                                            RUE
                                            EL AGHALIBA LE
                                            BARDO 2000</h6>
                                        <h6 style="margin: 0;text-align: center;font-size:medium;font-weight: bold;">
                                            Tél:70
                                            028 960-70 028
                                            970</h6>
                                        <h6 style="margin: 0;text-align: center;font-size:medium;font-weight: bold;">
                                            Fax:71
                                            512 288</h6>
                                        <h6 style="margin: 0;text-align: center;font-size:medium;">
                                            E-mail:pharmaconnect@planet.com</h6>
                                        <h6 style="margin: 0;text-align: center;font-size:medium;">Site
                                            Web: <a href="https://pharmaconnect.net">pharmaconnect.net</a></h6>
                                    </td>
                                    <td style="text-align: center;width:30%;margin: 0;padding:0">
                                        <h2 v-if="retourSaved.header != null">Proposition Retour
                                        </h2>
                                        <h2 v-if="retourSaved.header != null">
                                            {{ retourSaved.header.No }}
                                        </h2>
                                    </td>
                                    <td style="text-align: right;width:30%;margin: 0;padding:0">
                                        <h6 style="margin: 0;font-size:medium;font-weight: bold;">{{ new
                                            Date().toLocaleString() }}</h6>
                                        <h6 style="margin: 0;font-size:medium;font-weight: bold;">PHARMACONNECT\PRL</h6>
                                    </td>

                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr class="information">
                        <td>
                            <table>
                                <tr style="margin-top: 20px;margin-bottom: 20px">
                                    <td>
                                        <h6 style="margin: 20px; font-size:medium;" v-if="retourSaved.header != null">
                                            <strong>Bon de
                                                Retour N° : </strong>
                                            {{ retourSaved.header.No }}
                                        </h6>
                                        <h6 style="margin: 20px; font-size:medium;" v-if="retourSaved.header != null">
                                            <strong>Date
                                                : </strong>
                                            {{ retourSaved.header.Posting_Date.substring(0, 10) }}
                                        </h6>
                                    </td>

                                    <td>
                                        <h6 style="margin: 20px; font-size:medium;" v-if="retourSaved.header != null">
                                            <strong>Nom client : </strong>
                                            {{ retourSaved.header.Sell_to_Customer_Name }}
                                        </h6>
                                        <h6 style="margin: 20px; font-size:medium;" v-if="retourSaved.header != null">
                                            <strong>N° client :</strong>
                                            {{ retourSaved.header.Sell_to_Customer_No }}
                                        </h6>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>

                <table border="1" cellpadding="1" cellspacing="0" v-if="retourSaved.lines != null">
                    <tr class="heading">
                        <td class="bordered" style="text-align: center;width:7%;font-size: medium;">
                            Code
                        </td>
                        <td class="bordered" style="text-align: center;width:25%;font-size: medium;">
                            Désignation
                        </td>
                        <td class="bordered" style="text-align: center;width:7%;font-size: medium;">
                            Qté
                        </td>
                        <td class="bordered" style="text-align: center;width:15%;font-size: medium;">
                            Prix Unitaire
                        </td>
                        <td class="bordered" style="text-align: center;width:10%;font-size: medium;">
                            BL
                        </td>
                        <td class="bordered" style="text-align: center;width:23%;font-size: medium;">
                            Motif
                        </td>

                    </tr>

                    <tr class="details" :key="indextr" v-for="(tr, indextr) in retourSaved.lines">
                        <td class="bordered" style="text-align: center;width:10%;font-size: medium ">
                            {{ tr.No }}
                        </td>

                        <td class="bordered" style="text-align: left;width:35% ; font-size: medium">
                            {{ tr.Description }}
                        </td>
                        <td class="bordered" style="text-align: center;width:10%;font-size: medium   ">
                            {{ tr.Quantity }}
                        </td>
                        <td class="bordered" style="text-align: center;width:9%;font-size: medium  ">
                            {{ tr.Unit_Price }}
                        </td>
                        <td class="bordered" style="text-align: center;width:9%;font-size: medium  ">
                            {{ tr.N_x00B0__BL }}
                        </td>
                        <td class="bordered" style="text-align: center;width:30%;font-size: medium  ">
                            {{ tr.Reason_Description }}
                        </td>

                    </tr>

                </table>

                <br>

                <br>

                <br>

                <b-row>
                    <b-col cols="4" md="5">
                        <table border="1" cellpadding="0" cellspacing="0">

                            <!-- <img :src="cachet" style="width:250px"> -->

                        </table>
                    </b-col>
                    <b-col cols="2" md="2">
                    </b-col>

                </b-row>

            </div>
        </div>


        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>

                <b-row>
                    <b-col cols="9">
                        <label>Article</label>
                        <vue-autosuggest :suggestions="filteredOptions" :limit="10" item-text="description"
                            ref="autoSugg" item-value="code"
                            :input-props="{ id: 'autosuggest__input', class: 'd-inline-block form-control form-control-sm', placeholder: 'Recherche par désignation d\'article' }"
                            @input="onInputChange" :get-suggestion-value="getSuggestionValue" @selected="selectHandler">
                            <template slot-scope="{suggestion}">
                                <span class="my-suggestion-item">{{ suggestion.item.description }}</span>
                            </template>
                        </vue-autosuggest>
                    </b-col>
                    <b-col cols="3" class="text-right">
                        <b-button variant="success" class="mt-2" @click="validateReturnProposal" size="sm">
                            <feather-icon icon="SaveIcon" class="mr-50 ml-50" />
                            Valider
                        </b-button>
                    </b-col>
                </b-row>


                <ag-grid :showHeader="false" :agGrid="agGrid" ref="agGrid" />
            </b-card>
        </b-overlay>
    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import { VueAutosuggest } from 'vue-autosuggest'
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import printDocs from "@/utils/printDocs";

export default {

    metaInfo() {
        return {
            title: "Nouvelle demande de retour - PharmaConnect",
            meta: [
                {
                    vmid: "description",
                    name: "description",
                    content:
                        "Créez une nouvelle demande de retour de produits pharmaceutiques facilement sur PharmaConnect. Complétez les détails et soumettez votre demande auprès des grossistes.",
                },
                {
                    name: "robots",
                    content: "index, follow",
                },
                {
                    property: "og:title",
                    content: "Nouvelle demande de retour - PharmaConnect",
                },
                {
                    property: "og:description",
                    content: "Créez et soumettez une nouvelle demande de retour de produits pharmaceutiques en quelques clics sur PharmaConnect.",
                },
                {
                    property: "og:image",
                    content: "https://www.pharmaconnect.net/images/return-new-thumbnail.jpg",
                },
                {
                    property: "og:url",
                    content: "https://www.pharmaconnect.net/return-new",
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    name: "twitter:title",
                    content: "Nouvelle demande de retour - PharmaConnect",
                },
                {
                    name: "twitter:description",
                    content: "Créez et soumettez une nouvelle demande de retour de produits pharmaceutiques en quelques clics sur PharmaConnect.",
                },
                {
                    name: "twitter:image",
                    content: "https://www.pharmaconnect.net/images/return-new-thumbnail.jpg",
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: "https://www.pharmaconnect.net/return-new",
                },
            ],
            script: [
                // Structured Data using Schema.org
                {
                    type: "application/ld+json",
                    json: {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Nouvelle demande de retour",
                        "description": "Créez une nouvelle demande de retour de produits pharmaceutiques facilement sur PharmaConnect. Complétez les détails et soumettez votre demande auprès des grossistes.",
                        "url": "https://www.pharmaconnect.net/return-new"
                    },
                },
            ],
        };
    },

    components: {
        AgGrid,
        VueAutosuggest
    },
    data() {
        return {
            filteredOptions: [],
            src_logo: require('@/assets/images/logo/logo.png'),
            retourSaved: {
                lines: []
            },
            blNo: "",
            selectedMotif: {},
            showLoading: false,
            showLoadingModal: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Description Article",
                        field: "description",
                    },
                    {
                        headerName: "Qte Retour",
                        field: "qte",
                        editable: true,
                    },
                    {
                        headerName: "DLC",
                        field: "dlc",
                        editable: true,
                    },
                    {
                        headerName: "Motif",
                        field: "motif",
                        editable: true,
                    },
                    {
                        headerName: "N° BL",
                        field: "bl",
                        editable: true,
                    },
                    {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'buttonCellRenderer',
                        cellRendererParams: { componentName: 'NewOrder', onButtonClick: this.deleteProduct, icon: "backspace", variant: "outline-danger" },
                        headerName: '',
                        width: 80,
                    }
                ],
                rows: []
            },
            agGridLines: {
                columnDefs: [
                    {
                        headerName: "Document",
                        field: "Document_No",
                    },
                    {
                        headerName: "Date",
                        field: "Shipment_Date",
                    },
                    {
                        headerName: "N° Article",
                        field: "No",
                    },
                    {
                        headerName: "Description Article",
                        field: "Description",
                    },
                    {
                        headerName: "Qte Commandée",
                        field: "Quantity",
                    },
                ],
                rows: []
            },
        }
    },
    computed: {
        isNotMobileDevice() {
            return !/Mobi/i.test(navigator.userAgent)
        },
        datasuggest() {
            return this.$store.getters["app-items/geItemsMED"].concat(this.$store.getters["app-items/geItemsPARA"]);
        }
    },
    methods: {
        selectHandler(value) {
            let index = this.agGrid.rows.findIndex(item => item.description == value.item.description);
            if (index === -1) {
                this.agGrid.rows.unshift({
                    code: value.item.code,
                    description: value.item.description,
                    qte: "",
                    dlc: "",
                    motif: "",
                    bl: "",
                });
                index = 0;
            }
            this.$nextTick(() => {
                setTimeout(() => {
                    const rowIndex = index;
                    const colId = "qte";
                    const gridApi = this.$refs.agGrid.gridApi;
                    gridApi.startEditingCell({ rowIndex, colKey: colId });
                }, 200);
            });
        },
        getSuggestionValue(suggestion) {
            this.selected = suggestion.item;
            return suggestion.item.description
        },
        onInputChange(text) {
            if (text === '' || text === undefined) {
                return
            }
            /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
            const filteredData = this.datasuggest.filter(item => item.description.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
            this.filteredOptions = [{
                data: filteredData,
            }]
        },
        validateReturnProposal() {
            if (this.agGrid.rows.length == 0)
                return;
            let hasValidationErrors = false; // Flag to track validation errors
            this.agGrid.rows.forEach(element => {
                if (!this.containsOnlyDigits(element.qte)) {
                    this.$swal({
                        title: "Erreur de validation",
                        text: "Invalide Qte Retour pour l'article " + element.description,
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    });
                    hasValidationErrors = true; // Set flag to true
                    return;
                }
            });
            this.agGrid.rows.forEach(element => {
                if (element.bl.trim() == "") {
                    this.$swal({
                        title: "Erreur de validation",
                        text: "Il faut saisir le champ BL de l'article " + element.description,
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    });
                    hasValidationErrors = true; // Set flag to true
                    return;
                }
            });
            if (!hasValidationErrors) {
                let self = this;
                this.$swal.fire({
                    icon: 'question',
                    // showCancelButton: true,
                    // cancelButtonText: 'Annulé',
                    confirmButtonText: 'Oui, valider',
                    text: "Voules vous valider la proposition de retour ?"
                }).then(resp => {
                    if (resp.isConfirmed)
                        self.Confirmer();
                })
            }
        },
        async Confirmer() {
            this.retourSaved = {
                lines: []
            };
            let self = this;
            self.showLoading = true;
            // ----
            const resp = await self.$http.post("sales_return_header", {});
            // console.log(resp);
            if (resp.status == 200) {
                try {
                    const No = resp.data.No;
                    self.retourSaved.header = resp.data;
                    let Line_No = 10000;
                    const promises = self.agGrid.rows.map(async element => {
                        let itemNo = await this.$http.get("_item/getItemNoByItemCode/" + element.code).catch(() => this.showLoading = false);
                        if (itemNo.data === "") {
                            return;
                        }
                        let obj = {
                            Type: "Item",
                            Document_Type: "Return Order",
                            No: itemNo.data.toString(),
                            Quantity: parseInt(element.qte),
                            N_x00B0__BL: element.bl,
                            Reason_Description: element.motif,
                            Document_No: No,
                            Line_No: Line_No
                        };
                        // console.log(obj);
                        const resp = await self.$http.post("sales_return_line", obj);
                        // console.log(resp);
                        Line_No += 1000;
                        self.retourSaved.lines.push(resp.data);
                    });
                    // Wait for all promises to resolve
                    await Promise.all(promises);

                    self.agGrid.rows = [];
                    self.$nextTick(function () {
                        var contents = document.getElementById("printMeRetour").innerHTML;
                        printDocs.print(contents);
                    });
                    // self.selectedMotif = {};
                    // self.$router.push({ name: "return-proposal" })
                    // self.$swal({
                    //     text: 'Proposition de retour effectuée avec succès',
                    //     icon: 'success',
                    //     customClass: {
                    //         confirmButton: 'btn btn-primary',
                    //     },
                    //     showClass: {
                    //         popup: 'animate__animated animate__flipInX',
                    //     },
                    //     buttonsStyling: false,
                    // }).then(() => {
                    //     self.$nextTick(function () {
                    //         var contents = document.getElementById("printMeRetour").innerHTML;
                    //         printDocs.print(contents);
                    //     });
                    //     // self.$router.push({ name: "return-proposal" })
                    // });
                    // All requests are completed, hide loading
                    self.showLoading = false;
                } catch (error) {
                    self.showLoading = false;
                    // Handle errors if any request fails
                    console.error("Error:", error);
                    // You might want to handle errors appropriately here
                }
            } else {
                self.showLoading = false;
                self.$swal({
                    title: "Erreur de validation",
                    text: resp.data.error,
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                });
                // self.$swal({
                //     time: 10000,
                //     title: "Echec",
                //     text: resp.data.error,
                //     color: "danger",
                //     iconPack: "feather",
                //     icon: "icon-check",
                // });
                self.show = false;
            }
            // ----
        },
        containsOnlyDigits(input) {
            // Regular expression that matches only digits
            const digitPattern = /^[0-9]+$/;
            // Test the input against the regular expression
            return digitPattern.test(input);
        },
        deleteProduct(data) {
            const index = this.agGrid.rows.findIndex(product => product.description === data.description);
            if (index !== -1) {
                this.agGrid.rows.splice(index, 1);
                this.$refs.agGrid.gridOptions.api.setRowData(this.agGrid.rows);
            }
        },
        cellKeyPress(event) {
            if (event.event.key === "Enter") {
                const focusedCell = this.$refs.agGrid.gridOptions.api.getFocusedCell();
                if (focusedCell && focusedCell.column.colId === "qte") {
                    setTimeout(() => {
                        const rowIndex = event.rowIndex;
                        const colId = "dlc";
                        const gridApi = this.$refs.agGrid.gridApi;
                        gridApi.startEditingCell({ rowIndex, colKey: colId });
                    });
                } else if (focusedCell && focusedCell.column.colId === "dlc") {
                    setTimeout(() => {
                        const rowIndex = event.rowIndex;
                        const colId = "motif";
                        const gridApi = this.$refs.agGrid.gridApi;
                        gridApi.startEditingCell({ rowIndex, colKey: colId });
                    });
                } else if (focusedCell && focusedCell.column.colId === "motif") {
                    setTimeout(() => {
                        const rowIndex = event.rowIndex;
                        const colId = "bl";
                        const gridApi = this.$refs.agGrid.gridApi;
                        gridApi.startEditingCell({ rowIndex, colKey: colId });
                    });
                }
            }
        },
    },
    created() {
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        setTimeout(() => {
            this.agGrid.rows = [];
            // this.$refs["autoSugg"].focus()
            this.$refs["autoSugg"].$el.querySelector("input").focus();
            this.$nextTick(() => {
                const gridApi = this.$refs.agGrid.gridApi;
                gridApi.addEventListener("cellKeyPress", this.cellKeyPress);
                this.disableBtn();
            });
        }, 300);
    },
    beforeDestroy() {
        const gridApi = this.$refs.agGrid.gridApi;
        gridApi.removeEventListener("cellKeyPress", this.cellKeyPress);
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card-body {
        /* Display the card on screens wider than 768px */
        // display: block;
        padding: 0px;
    }

    .card {
        padding: 0px;
        background-color: initial;
        box-shadow: initial;
    }
}
</style>